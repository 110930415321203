/**
 * A simple cache that stores objects in memory.
 */
class MemoryCache {
  constructor () {
    this._cache = new Map()
  }

  set (key, data) {
    return this._cache.set(key, data)
  }

  get (key) {
    return this._cache.get(key)
  }
}

const defaultSmartConfig = {
  baseDelay: 400,
  logger: console,
  numberOfTries: 3,
  cache: new MemoryCache()
}

/**
 * A smart version of the native fetch
 * that supports results caching and connection retries.
 *
 * In case of a successful request, returns a JSON response.
 *
 * @param url - url to fetch
 * @param config - native fetch request config
 * @param smartConfig - smart fetch config, see defaultSmartConfig for options.
 * @returns a promise, that in case of success, returns a JSON response, so, contrary to
 * the native fetch, you don't need to call .json() on its return.
 *
 * Usage example:
 * ```
 * const posts = await smartFetch(
 *   'https://my-json-server.typicode.com/typicode/demo/posts',
 *   {},
 *   {numberOfTries: 10}
 * );
 * ```
 */
export async function smartFetch (url, config = {}, smartConfig = {}) {
  const { cache, ...mergedSmartConfig } = {
    ...defaultSmartConfig,
    ...smartConfig
  }

  return withRequestCache(withRetryHandling(fetch, mergedSmartConfig), cache)(
    url,
    config
  )
}

/**
 * A wrapper function that caches results of a fetch callback.
 */
const withRequestCache = (callback, cache) => {
  return async function callbackWithRequestCache (url, config) {
    if (config && config.method && config.method.toLowerCase() !== 'get') {
      return callback(url, config)
    }

    if (!cache.get(url)) {
      const response = await callback(url, config)
      const result = await response.json()
      cache.set(url, result)
    }

    return cache.get(url)
  }
}

/**
 * A wrapper function that retries failed fetch requests.
 */
const withRetryHandling = (
  func,
  { baseDelay = 400, logger = console, numberOfTries = 3 } = {}
) =>
  function funcWithRetryHandling (...params) {
    const retry = async (attempt = 1) => {
      try {
        const response = await func(...params)
        if (!response.ok) {
          throw new Error(`Response returned status ${response.status}`)
        }
        return response
      } catch (error) {
        if (attempt >= numberOfTries) {
          throw error
        }

        const delay = baseDelay * attempt

        if (logger) {
          logger.warn('Retry because of', error)
        }

        return new Promise((resolve) =>
          setTimeout(() => resolve(retry(attempt + 1)), delay)
        )
      }
    }

    return retry()
  }
