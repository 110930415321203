import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['exercise', 'submit']

  connect () {
    console.log(this.exerciseTargets)
  }

  checkInputs () {
    // Logic to check input values and enable/disable submit button
    const allFilled = this.exerciseTargets.every(input => input.value.trim() !== '')
    this.submitTarget.disabled = !allFilled
  }

  submit (event) {
    event.preventDefault()
    // Submit logic here
  }
}
