import Dropdown from './dropdown_controller.js'

export default class extends Dropdown {
  static targets = ['menu', 'overlay']

  _show () {
    this.overlayTarget.classList.remove(this.toggleClass)
    console.log('showing')

    super._show(
      () => {
        this._activeClassList[1].forEach((klass) =>
          this.overlayTarget.classList.add(klass)
        )
        this._invisibleClassList[1].forEach((klass) =>
          this.overlayTarget.classList.remove(klass)
        )
        this._visibleClassList[1].forEach((klass) =>
          this.overlayTarget.classList.add(klass)
        )
        setTimeout(
          () => {
            this._enteringClassList[1].forEach((klass) =>
              this.overlayTarget.classList.remove(klass)
            )
          },
          this.enterTimeout[1]
        )
      }
    )
  }

  _hide () {
    this._leavingClassList[1].forEach((klass) =>
      this.overlayTarget.classList.add(klass)
    )
    console.log('hiding')

    super._hide(
      () => {
        setTimeout(
          () => {
            this._visibleClassList[1].forEach((klass) =>
              this.overlayTarget.classList.remove(klass)
            )
            this._invisibleClassList[1].forEach((klass) =>
              this.overlayTarget.classList.add(klass)
            )
            this._activeClassList[1].forEach((klass) =>
              this.overlayTarget.classList.remove(klass)
            )
            this._leavingClassList[1].forEach((klass) =>
              this.overlayTarget.classList.remove(klass)
            )
            this.overlayTarget.classList.add(this.toggleClass)
          },
          this.leaveTimeout[1]
        )
      }
    )
  }
}
