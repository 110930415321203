import { Controller } from '@hotwired/stimulus'

/**
 * A TurboStreams dispatcher for the stelect-add-option.
 *
 * This controller is a mere messenger that's sent by TurboStreams.
 *
 * When this component is rendered, it looks for the 'stelect-add-option'
 * controller via the stelectId and passes option values to the controller,
 * which in turn, selects current option.
 */
export default class extends Controller {
  static values = {
    optionValue: String,
    optionLabel: String,
    stelectId: String
  }

  connect () {
    const addOptionController = document.getElementById(this.stelectIdValue)
    addOptionController &&
      addOptionController.selectOption(
        this.optionValueValue,
        this.optionLabelValue
      )
  }
}
