import { Controller } from '@hotwired/stimulus'

// And their styles (for UI plugins)
// With webpack and `style-loader`, you can import them like this:
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default class extends Controller {
  static targets = ['uploader', 'input', 'list']

  connect () {
    Promise.all([
      import('@uppy/core'),
      import('@uppy/dashboard'),
      import('@uppy/aws-s3')
    ]).then(([Uppy, Dashboard, AwsS3]) => {
      this.initUppyInstance(
        this,
        Uppy.default,
        Dashboard.default,
        AwsS3.default
      )
      this.createFileList(this)
    })
  }

  initUppyInstance (controller, Uppy, Dashboard, AwsS3) {
    const uppy = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 300000000,
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', 'video/*']
      }

    })

    uppy.use(Dashboard, {
      target: controller.uploaderTarget,
      height: 250,
      inline: true,
      strings: {
        dropPasteFiles: 'Drip files here or %{browseFiles}'
      }
    })

    uppy.use(AwsS3, {
      getUploadParameters (file) {
        return fetch('/s3-sign/', {
          method: 'post',
          // Send and receive JSON.
          headers: {
            accept: 'application/json',
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            filename: file.name,
            contentType: file.type
          })
        })
          .then((response) => {
            // Parse the JSON response.
            return response.json()
          })
          .then((data) => {
            // Return an object in the correct shape.
            return {
              method: data.method,
              url: data.url,
              fields: data.fields,
              headers: data.headers
            }
          })
      }
    })

    uppy.on('upload-success', (file, data) => {
      // the S3 object key of the uploaded file
      const s3Key = file.meta.key

      controller.addFileToInput(controller, s3Key, data.uploadURL)

      const li = controller.createFileListItem(s3Key, data.uploadURL)
      controller.listTarget.appendChild(li)
    })
  }

  createFileList (controller) {
    const fileArray = JSON.parse(this.inputTarget.value)
    const listTarget = this.listTarget

    fileArray.forEach(function (data) {
      const li = controller.createFileListItem(data.key, data.url)
      listTarget.appendChild(li)
    })
  }

  createFileListItem (s3Key, url) {
    const uploadURL = decodeURIComponent(url)
    const filename = uploadURL.split('/').pop()

    const html = `
          <button
            class="mr-2 py-2 px-4 bg-red-700 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none"
            data-value="${s3Key}"
            data-action="click->upload#deleteHandler"
          >
            Delete
          </button>

          <a class="mr-2 py-2 px-4 bg-blue-700 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none" href="${uploadURL}" download rel="noopener noreferrer" target="_blank">
           Download
          </a>

          <p class="text-xs text-gray-600 truncate">${filename}</p>
      `

    const li = document.createElement('li')
    li.classList.add('flex', 'items-center', 'p-3')
    li.innerHTML = html

    return li
  }

  deleteHandler (event) {
    event.preventDefault()

    // update file array
    const s3Key = event.target.dataset.value

    this.deleteFileFromInput(this, s3Key)

    event.target.closest('li').classList.add('hidden')
  }

  addFileToInput (controller, key, url) {
    // init input value
    if (controller.inputTarget.value === '') {
      controller.inputTarget.value = JSON.stringify([])
    }

    const fileArray = JSON.parse(controller.inputTarget.value)
    fileArray.push({
      key,
      url
    })

    controller.inputTarget.value = JSON.stringify(fileArray)
  }

  deleteFileFromInput (controller, key) {
    const fileArray = JSON.parse(controller.inputTarget.value)
    const index = fileArray.findIndex((obj) => obj.key === key)
    if (index > -1) {
      fileArray.splice(index, 1)
    }
    controller.inputTarget.value = JSON.stringify(fileArray)
  }
}
