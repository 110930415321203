import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static values = {
    animation: Number,
    handle: String,
    draggable: String,
    positionInputName: String
  }

  get $items () {
    return Array.from(this.element.querySelectorAll(this.draggableValue))
  }

  get positionInputSelector () {
    return `input[type=number][name$="-${this.positionInputNameValue}"]`
  }

  initialize () {
    this.positionInputNameValue = this.positionInputNameValue || 'position'
    this.handleEnd = this.handleEnd.bind(this)
    this.updateItemPosition = this.updateItemPosition.bind(this)
  }

  connect () {
    // Expose updateItemPosition for external usage.
    this.element.__updateItemPosition = this.updateItemPosition.bind(this)
    this.initSortable()
    this.updateItemsPositions(this.$items)
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

  initSortable () {
    this.sortable = new Sortable(this.element, {
      animation: this.animationValue || 150,
      draggable: this.draggableValue,
      handle: this.handleValue,
      onEnd: this.handleEnd
    })
  }

  handleEnd () {
    this.updateItemsPositions(this.$items)
  }

  updateItemsPositions (items) {
    items.forEach(this.updateItemPosition)
  }

  updateItemPosition (item, index) {
    item.querySelector(this.positionInputSelector).value = index
  }
}
