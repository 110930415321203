import { Controller } from '@hotwired/stimulus'
import { Editor } from '@tiptap/core'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import Youtube from '@tiptap/extension-youtube'
import Image from '@tiptap/extension-image'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import { decode } from 'html-entities'

const CustomYoutube = Youtube.extend({
  parseHTML () {
    return [
      {
        tag: 'div[data-youtube-video] iframe'
      },
      {
        tag: 'iframe'
      }
    ]
  }
})

export default class extends Controller {
  static targets = ['editorarea']

  connect () {
    this.input = this.element.querySelector('input')
    this.editor = new Editor({
      element: this.editorareaTarget,
      extensions: [
        Document,
        Paragraph,
        Text,
        Image,
        Bold,
        ListItem,
        BulletList,
        OrderedList,
        Italic.configure({
          HTMLAttributes: {
            class: 'italic'
          }
        }),
        CustomYoutube
      ],
      content: decode(this.input.value),
      editorProps: {
        attributes: {
          class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl focus:outline-none p-2'
        }
      }
    })

    this.input.form.addEventListener('submit', () => {
      this.input.value = this.editor.getHTML()
    })
    this.schema = this.editor.schema
  }

  toggleBold () {
    this.editor.chain().focus().toggleBold().run()
  }

  toggleItalic () {
    this.editor.chain().focus().toggleItalic().run()
  }

  toggleBulletList () {
    this.editor.commands.toggleBulletList()
    // need to make button show as active/inactive
    // this.editor.isActive('bulletList') ? 'is-active' : ''
  }

  toggleOrderedList () {
    this.editor.commands.toggleOrderedList()
    // need to make button show as active/inactive
    // this.editor.isActive('orderedList') ? 'is-active' : ''
  }

  setImage () {
    const url = prompt('Enter Image URL')
    if (url) { this.editor.commands.setImage({ src: url }) }
  }

  addYoutubeVideo () {
    let url = prompt('Enter YouTube URL')

    if (url) {
      url = url.replace('/shorts/', '/embed/')
      this.editor.commands.setYoutubeVideo({
        src: url
      })
    }
  }
}
