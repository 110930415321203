import { Controller } from '@hotwired/stimulus'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

export default class extends Controller {
  static targets = ['image', 'crop_x', 'crop_y', 'crop_width', 'crop_height']
  static values = { model: String }

  changed () {
    console.log('Image changed. Firing cropper')
    this.createCropper()
  }

  createCropper () {
    const _this = this
    if (this.cropper) {
      this.cropper.destroy() // Destroy the existing cropper instance if it exists
    }

    this.cropper = new Cropper(this.imageTarget, {
      aspectRatio: 1,
      zoomOnWheel: true,
      zoomable: false,
      crop: (event) => {
        this.handleCrop(event)
        _this.crop_x().value = event.detail.x
        _this.crop_y().value = event.detail.y
        _this.crop_width().value = event.detail.width
        _this.crop_height().value = event.detail.height
      }
    })
  }

  handleCrop (event) {
    this.crop_xTarget.value = event.detail.x
    this.crop_yTarget.value = event.detail.y
    this.crop_widthTarget.value = event.detail.width
    this.crop_heightTarget.value = event.detail.height
  }

  crop_x () {
    if (this._crop_x === undefined) {
      this._crop_x = document.createElement('input')
      this._crop_x.name = `${this.modelValue}[crop_x]`
      this._crop_x.type = 'hidden'
      this.imageTarget.parentNode.insertBefore(this._crop_x, this.imageTarget.nextSibling)
    }
    console.log(`${this._crop_x.value}x`)
    return this._crop_x
  }

  crop_y () {
    if (this._crop_y === undefined) {
      this._crop_y = document.createElement('input')
      this._crop_y.name = `${this.modelValue}[crop_y]`
      this._crop_y.type = 'hidden'
      this.imageTarget.parentNode.insertBefore(this._crop_y, this.imageTarget.nextSibling)
    }
    console.log(`${this._crop_y.value}y`)
    return this._crop_y
  }

  crop_width () {
    if (this._crop_width === undefined) {
      this._crop_width = document.createElement('input')
      this._crop_width.name = `${this.modelValue}[crop_width]`
      this._crop_width.type = 'hidden'
      this.imageTarget.parentNode.insertBefore(this._crop_width, this.imageTarget.nextSibling)
    }
    console.log(`${this._crop_width.value}height`)
    return this._crop_width
  }

  crop_height () {
    if (this._crop_height === undefined) {
      this._crop_height = document.createElement('input')
      this._crop_height.name = `${this.modelValue}[crop_height]`
      this._crop_height.type = 'hidden'
      this.imageTarget.parentNode.insertBefore(this._crop_height, this.imageTarget.nextSibling)
    }
    console.log(`${this._crop_height.value}height`)
    return this._crop_height
  }
}
