import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['exitbutton', 'dock']

  connect () {
    this.show()
  }

  show () {
    this.exitbuttonTarget.classList.remove('hidden')
    this.dockTarget.classList.remove('hidden')
    setTimeout(() => this.hide(), 4000)
  }

  hide () {
    this.exitbuttonTarget.classList.add('hidden')
    this.dockTarget.classList.add('hidden')
  }
}
